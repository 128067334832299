import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Header = styled.h1`
  margin: 4rem auto;
  padding: 2rem 0;
  text-align: center;
  color: #0a1642;
  max-width: 700px;

  @media (max-width: 860px) {
    margin: 2rem auto 0;
    font-size: 1.5rem;
  }
`

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 740px) {
    flex-direction: column;
  }
`

const Card = styled.a`
  margin: 3rem;
  padding: 2.5rem;
  width: 300px;
  height: 300px;
  border-radius: 0.75rem;
  background-color: rgb(255 255 255);
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 740px) {
    margin: 2rem;
  }

  @media (max-width: 460px) {
    margin: 1rem;
    width: 250px;
    height: 250px;
  }

  @media (max-width: 320px) {
    margin: 1rem;
    width: 220px;
    height: 220px;
  }
`

const BmsCard = styled(Card)`
  background: linear-gradient(90deg, #3faffd, #259dfc, #6e56d7);
`

const HoamCard = styled(Card)`
  background: linear-gradient(90deg, #50a7ff, #057ffb);
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Header>Which account settings would you like to update?</Header>
    <CardContainer>
      <BmsCard href="https://app.businessmadesimple.com/account">
        <StaticImage
          src="../images/BMS-Logo.png"
          width={180}
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Business Made Simple Logo"
          backgroundColor={`transparent`}
          placeholder="tracedSVG"
        />
      </BmsCard>
      <HoamCard
        // themeColor="#0a1642"
        href="https://app.heroonamission.com/account"
      >
        <StaticImage
          src="../images/HOAM-Logo-White.png"
          width={140}
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Hero on a Mission Logo"
          backgroundColor={`transparent`}
          placeholder="tracedSVG"
        />
      </HoamCard>
    </CardContainer>
  </Layout>
)

export default IndexPage
